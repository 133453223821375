import quote from "../assets/images/quote2.svg"
import { useState, useRef } from "react"
import background from "../assets/images/bg5.png"

const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const sliderRef = useRef()
    const sliderContainerRef = useRef()

    const handlePreviousSlide = () => {
        if(!sliderRef || !sliderContainerRef) {
            return
        }
        
        let totalSlidableItems = sliderContainerRef.current?.children?.length - window.getComputedStyle(sliderRef.current).getPropertyValue("--slider-items")

        if(currentSlide <= 0) {
            setCurrentSlide(totalSlidableItems)
        } else {
            setCurrentSlide(currentSlide - 1)
        }
    }

    const handleNextSlide = () => {
        if(!sliderRef || !sliderContainerRef) {
            return
        }

        let totalSlidableItems = sliderContainerRef.current?.children?.length - window.getComputedStyle(sliderRef.current).getPropertyValue("--slider-items")

        if(currentSlide >= totalSlidableItems) {
            setCurrentSlide(0)
        } else {
            setCurrentSlide(currentSlide + 1)
        }
    }

    return (
        <section className="section testimonial has-bg-image" id="testimonial" aria-labelledby="testimonial-label" style={{backgroundImage: `url(${background})`}}>
            <div className="container">
                <div>
                    <h2 className="h2 section-title" id="testimonial-label">Here's what they think...</h2>
                </div>

                <div className="slider" ref={sliderRef} data-slider>
                    <ul className="slider-container testimonial-list" ref={sliderContainerRef} style={{transform: `translateX(-${sliderContainerRef?.current?.children[currentSlide]?.offsetLeft}px)`}} data-slider-container>
                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    Everything is running smoothly. This would have never have happened without you.
                                    Thank you for your outstanding support.
                                </p>

                                <p className="client-name">Philip D.</p>
                                <p className="client-title"></p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    I am an advisor who is joining Cadaret Grant today from Woodbury Financial.  
                                    I want to thank you and let you know how helpful Krysten Jackson has been for us. 
                                    She is so efficient and has the patience of a saint!  
                                    I thought you would want to know you picked the right company to help us through this transition.
                                </p>

                                <p className="client-name">Stacie D.</p>
                                <p className="client-title">Reg Rep</p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    Thank you so much for all of your help and patience with
                                    me. You really helped me BIG TIME!!!!
                                </p>

                                <p className="client-name">Daniel D.</p>
                                <p className="client-title">Financial Representative</p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    Is it done yet??? Just kidding, you are so fast I just had to say that. 
                                    I just want you to know I truly appreciate you and how you have stayed so calm and polite during this mess. 
                                    I know it hasn't been easy and these guys can be major prima donnas! I apologize for any abuse you have been put through. 
                                    I don't think they realize how demanding they are and that you are working with 11 guys. 
                                    You are doing fantastic! Many blessings to you and I think you deserve an extra gem in your crown when you get to Heaven :) 
                                </p>

                                <p className="client-name">Barbara G.</p>
                                <p className="client-title"></p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    You rock....
                                </p>

                                <p className="client-name">Debbie G.</p>
                                <p className="client-title">Operations / Office Manager</p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    THANK YOU SO MUCH!!!! YOU HAVE BEEN AMAZING TO WORK WITH!!!!
                                </p>

                                <p className="client-name">Anthony M.</p>
                                <p className="client-title">President, Managing Partner</p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    We truly appreciate all your help and feel your service was/is a tremendous value. Thanks.
                                </p>

                                <p className="client-name">Thomas D.</p>
                                <p className="client-title"></p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>

                        <li className="slider-item" data-slider-item>
                            <div className="testimonial-card">
                                <p className="card-text">
                                    You are AWESOME!
                                </p>

                                <p className="client-name">George H.</p>
                                <p className="client-title"></p>

                                <img src={quote} width={50} height={50} loading="lazy" className="icon" alt="quote" />
                            </div>
                        </li>
                    </ul>

                    <button className="slider-btn prev" aria-label="slide to previous" onClick={() => handlePreviousSlide()} data-slider-prev>
                        <ion-icon name="arrow-back"></ion-icon>
                    </button>

                    <button className="slider-btn next" aria-label="slide to next" onClick={() => handleNextSlide()} data-slider-next>
                        <ion-icon name="arrow-forward"></ion-icon>
                    </button>

                    {/* <div className="slider-controls">
                        <button className="slider-control prev" onClick={() => handlePreviousSlide()} data-slider-prev aria-label="Slide to previous item">
                            <div className="line"></div>
                            <div className="arrow"></div>
                        </button>

                        <button className="slider-control next" onClick={() => handleNextSlide()}  data-slider-next aria-label="Slide to next item">
                            <div className="line"></div>
                            <div className="arrow"></div>
                        </button>
                    </div> */}
                </div>
            </div>  
        </section>
    )
}

export default Testimonials