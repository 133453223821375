import aboutBanner from "../assets/images/about-us-banner.jpg"

const About = () => {
    return (
        <section className="section about" aria-labelledby="about-label" id="about">
            <div className="container">
                <div className="about-content">
                    <p className="section-subtitle" id="about-label">What we’re about</p>

                    <h2 className="h2 section-title">
                        Experience. Speed. Accuracy
                    </h2>

                    <div className="about-item">
                        <div className="accordion-card expanded">
                            <p className="accordion-about-content">
                                Since its inception in 2013, Beyond Advising has worked closely with independent financial advisors to drive the change to a new broker-dealer.  
                                The firm’s track record over the past ten years speaks for itself, with over $5 billion AUM transferred with less than 1% NIGO rate.
                            </p>
                            <p className="accordion-about-content">
                                Beyond Advising adds value to a transition through our simultaneous approach. 
                                Our team completes the necessary account paperwork while the advisor is still registered with their current 
                                broker-dealer so they can obtain client signatures immediately upon registration with the new broker-dealer. 
                            </p>
                            <p className="accordion-about-content">
                                Beyond Advising focuses on best practices initiatives across the transition process. 
                                Our team has extensive experience working in various specialties within the financial services sector. 
                            </p>
                            <p className="accordion-about-content">
                                Beyond Advising provides significant value to our advisors by leveraging our resources to provide a seamless and smooth 
                                transition with little interruption in commissions received to the advisor. Many of our advisors have benefited from our 
                                new account forms' speed, accuracy, and clarity.
                            </p>
                         </div>
                    </div>
                </div>

                <figure className="about-banner" role="none">
                    <img src={aboutBanner} width="800" height="580" loading="lazy" alt="about banner" className="w-100" />
                </figure>
            </div>
        </section>
    )
}

export default About