import blog1 from "../assets/images/blog-1.jpg"
import blog2 from "../assets/images/blog-2.jpg"
import blog3 from "../assets/images/blog-3.jpg"

const Service = () => {
    return (
        <section className="section project" aria-labelledby="project-label" id="project">
            <div className="container">
                <p className="section-subtitle" id="project-label">Services</p>

                <h2 className="h2 section-title">
                    A Robust Transition Process
                </h2>

                <ul className="grid-list">
                    <li>
                        <div className="project-card">
                            <figure className="card-banner img-holder" role="none">
                                <img src={blog1} width="560" height="350" loading="lazy" alt="card1" className="img-cover" />
                            </figure>

                            <div className="card-content">
                                <h3 className="h3">
                                    <span className="card-title">New Account Paperwork</span>
                                </h3>

                                <p className="card-text">
                                    Curate all required forms for each account. Complete forms with customer information. 
                                    Indicate where information is missing. Indicate where signatures are needed.
                                </p>

                                {/* <ul className="card-meta-list">
                                    <li className="card-meta-item">
                                        <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                                        <time className="meta-text" dateTime="2022-04-14">14 Apr 2012</time>
                                    </li>

                                    <li className="card-meta-item">
                                        <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                                        <span className="meta-text">Coding</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="project-card">
                            <figure className="card-banner img-holder" role="none">
                                <img src={blog2} width="560" height="350" loading="lazy" alt="card2" className="img-cover" />
                            </figure>

                            <div className="card-content">
                                <h3 className="h3">
                                    <span className="card-title">Commitment to Clients</span>
                                </h3>

                                <p className="card-text">
                                    One on one phone calls with clients to walk through forms page by page. 
                                    Facilitate client events. Readily available to answer any questions that may arise.
                                </p>

                                {/* <ul className="card-meta-list">
                                    <li className="card-meta-item">
                                        <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                                        <time className="meta-text" dateTime="2022-04-14">14 Apr 2012</time>
                                    </li>

                                    <li className="card-meta-item">
                                        <ion-icon name="document-text-outline" aria-hidden="true"></ion-icon>

                                        <span className="meta-text">Coding</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="project-card">
                            <figure className="card-banner img-holder" role="none">
                                <img src={blog3} width="560" height="350" loading="lazy" alt="card3" className="img-cover" />
                            </figure>

                            <div className="card-content">
                                <h3 className="h3">
                                    <span className="card-title">Printing and Mailing</span>
                                </h3>

                                <p className="card-text">
                                    Coordinate with printer to ensure all files are printed accurately, 
                                    all disclosures are present, and postage-paid envelopes are included.
                                </p>

                                {/* <ul className="card-meta-list">
                                    <li className="card-meta-item">
                                        <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                                        <time className="meta-text" dateTime="2022-04-14">14 Apr 2012</time>
                                    </li>

                                    <li className="card-meta-item">
                                        <ion-icon name="document-text-outline" aria-hidden="true"></ion-icon>

                                        <span className="meta-text">Coding</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Service