import aboutBanner from "../assets/images/solution-banner.jpg"
import { useState } from "react"

const Solution = () => {
    const [currentActiveAccordion, setCurrentActiveAccordion] = useState([0, 1])
    
    const handleAccordionToggle = (item) => {
        let updatedCurrentActiveAccordion = [...currentActiveAccordion]
        if(updatedCurrentActiveAccordion.includes(item)) {
            const index = updatedCurrentActiveAccordion.indexOf(item);
            if (index > -1) {
                updatedCurrentActiveAccordion.splice(index, 1)
            }
        } else {
            setCurrentActiveAccordion(updatedCurrentActiveAccordion.push(item))
        }
        setCurrentActiveAccordion(updatedCurrentActiveAccordion)
    }

    return (
        <section className="section about" aria-labelledby="about-label">
            <div className="container">
                <figure className="about-banner" role="none">
                    <img src={aboutBanner} width="800" height="580" loading="lazy" alt="about banner" className="w-100" />
                </figure>

                <div className="about-content">
                    <p className="section-subtitle" id="about-label">Don't let paperwork stop you</p>

                    <h2 className="h2 section-title">
                        Solutions for your successful transition
                    </h2>

                    <ul>
                        <li className="about-item">
                            <div className={"accordion-card" + (currentActiveAccordion.includes(0) ? " expanded" : "")}>
                                <h3 className="card-title">
                                    <button className="accordion-btn" onClick={() => handleAccordionToggle(0)}>
                                        <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                                        <span className="span h5">Client Paperwork</span>
                                    </button>
                                </h3>

                                <p className="accordion-content">
                                    We serve as a knowledgeable and efficient extension of your staff. 
                                    We bring our experience, resources, and bandwidth to complete all client paperwork before your official transition proactively. 
                                </p>
                            </div>
                        </li>

                        <li className="about-item">
                            <div className={"accordion-card" + (currentActiveAccordion.includes(1) ? " expanded" : "")}>
                                <h3 className="card-title">
                                    <button className="accordion-btn" onClick={() => handleAccordionToggle(1)}>
                                        <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                                        <span className="span h5">Transition Support</span>
                                    </button>
                                </h3>

                                <p className="accordion-content">
                                    We manage and execute specific transition-specific projects. 
                                    We leverage our experience and resources to tackle these targeted areas promptly and effectively. 
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Solution