const Advantage = () => {
    return (
        <section className="section service" aria-labelledby="service-label" id="advantage">
            <div className="container">
                {/* <p className="section-subtitle" id="service-label">What We Do?</p> */}
                <h2 className="h2 section-title">
                    Advantages We Offer
                </h2>

                <ul className="grid-list">
                    <li>
                        <div className="service-card">
                            <div className="card-icon">
                                <ion-icon name="eye-outline" aria-hidden="true"></ion-icon>
                            </div>

                            <h3 className="h4 card-title">Stay Focused</h3>
                            <p className="card-text">
                                High-quality, seamless transitions are time-consuming. We streamline and optimize time spent on the transition so that 
                                advisors stay focused on investing, and support staff can stay focused on daily responsibilities. We eliminate the extra 
                                burden a transition puts on an office.
                            </p>

                            {/* <a href="#" className="btn-text">
                                <span className="span">Learn More</span>

                                <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                            </a> */}
                        </div> 
                    </li>

                    <li>
                        <div className="service-card">
                            <div className="card-icon">
                                <ion-icon name="trending-down-outline" aria-hidden="true"></ion-icon>
                            </div>

                            <h3 className="h4 card-title">Reduced NIGOs</h3>
                            <p className="card-text">
                                Limit interruptions to client accounts by reducing errors, and decreasing the need to meet multiple times. We do transitions 
                                daily, know which forms to use, and where missing information will be kicked back for NIGO. We ensure your clients know where 
                                to fill in the missing information, and where to sign.
                            </p>

                            {/* <a href="#" className="btn-text">
                                <span className="span">Learn More</span>

                                <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                            </a> */}
                        </div> 
                    </li>

                    <li>
                        <div className="service-card">
                            <div className="card-icon">
                                <ion-icon name="stats-chart-outline" aria-hidden="true"></ion-icon>
                            </div>

                            <h3 className="h4 card-title">Cost Effective</h3>
                            <p className="card-text">
                                Our fees are often covered by a transition assistance package offered by the new broker-dealer. Our expertise provides cost savings 
                                instead of hiring and training in-house transition professionals. In addition, experienced transitions reduce client frustrations, resulting 
                                in a higher account transition success rate.
                            </p>

                            {/* <a href="#" className="btn-text">
                                <span className="span">Learn More</span>

                                <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                            </a> */}
                        </div> 
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Advantage