import "./styles/style.css"
import Header from "./components/Header";
import Hero from "./components/Hero";
import Advantage from "./components/Advantage";
import Solution from "./components/Solution";
import Service from "./components/Service";
import Footer from "./components/Footer";
import About from "./components/About";
import Testimonials from "./components/Testimonials";

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <article>
          <Hero />
          <Solution />
          <Service />
          <Advantage />
          <Testimonials />
          <About />
          <Footer />
        </article>
      </main>
    </div>
  )
}

export default App;
