import { useState, useEffect } from "react"

const Header = () => {
    const [navbarToggle, setNavbarToggle] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.scrollY;
        console.log(position)
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavbarToggle = () => {
        setNavbarToggle(!navbarToggle)
    }

    return (
        <header className={"header" + (scrollPosition > 100 ? " active" : "")} data-header>
            <div className="container">
                <a href="#" className="logo" role="logo">
                    <div className="logo-light">Beyond Advising</div>
                    <div className="logo-dark">Beyond Advising</div>
                    {/* <img src={logoLight} width="74" height="24" alt="Home" className="logo-light" />
                    <img src={logoDark} width="74" height="24" alt="Home" className="logo-dark" /> */}
                </a>

                <nav className={"navbar" + (navbarToggle ? " active" : "")}>
                    <div className="navbar-top">
                        <a href="#" className="logo" role="logo">
                            {/* <img src={logoLight} width="74" height="24" alt="Home" /> */}
                            <div className="navbar-top-logo">Beyond Advising</div>
                        </a>

                        <button className="nav-close-btn" aria-label="close menu" onClick={() => handleNavbarToggle()}>
                            <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
                        </button>
                    </div>

                    <ul className="navbar-list">
                        <li>
                            <a href="#" className="navbar-link" role="link">Home</a>
                        </li>
                        <li>
                            <a href="#project" className="navbar-link" role="link">Services</a>
                        </li>
                        <li>
                            <a href="#about" className="navbar-link" role="link">About</a>
                        </li>
                        <li>
                            <a href="#footer" className="navbar-link" role="link">Contact Us</a>
                        </li>
                    </ul>

                    <div className="wrapper">
                        <a href="mailto:Info@BeyondAdvising.com" className="contact-link">Info@BeyondAdvising.com</a>
                        {/* <a href="tel: 0123 4343 21" className="contact-link">0123 4343 21</a> */}
                    </div>

                    <ul className="social-list">
                        <li>
                            <a href="https://www.linkedin.com/company/beyondadvising" className="social-link">
                                <ion-icon name="logo-linkedin"></ion-icon>
                            </a>
                        </li>
                    </ul>
                </nav>

            {/* <a href="#" className="btn btn-primary">Free Trial</a> */}

            <button className="nav-open-btn" aria-label="open menu" onClick={() => handleNavbarToggle()}>
                <ion-icon name="menu-outline" aria-hidden="true"></ion-icon>
            </button>

            <div className={"overlay" + (navbarToggle ? " active" : "")} data-nav-toggler data-overlay onClick={() => handleNavbarToggle()}></div>
        </div>
      </header>
    )
}

export default Header