import background from "../assets/images/bg4.png"
import heroSlide1 from "../assets/images/image-asset.jpg"
import heroSlide2 from "../assets/images/image-asset2.jpg"
import heroSlide3 from "../assets/images/image-asset3.jpg"

import { useState, useRef } from "react"

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const sliderContainerRef = useRef()

    const handlePreviousSlide = () => {
        if(!sliderContainerRef) {
            return
        }

        if(currentSlide <= 0) {
            setCurrentSlide(sliderContainerRef.current?.children?.length - 1)
        } else {
            setCurrentSlide(currentSlide - 1)
        }
    }

    const handleNextSlide = () => {
        if(!sliderContainerRef) {
            return
        }

        if(currentSlide >= sliderContainerRef.current?.children?.length - 1) {
            setCurrentSlide(0)
        } else {
            setCurrentSlide(currentSlide + 1)
        }
    }

    return (
        <section className="section hero has-bg-image" aria-label="home" style={{backgroundImage: `url(${background})`}}>
            <div className="container">
                <div className="hero-content">
                    <h1 className="h1 hero-title">
                        What we do
                    </h1>

                    <p className="hero-text">
                        We assist financial advisors in transitioning their book of business to a new broker-dealer 
                        by delivering prepopulated new account paperwork held at clearing firms, independent RIAs, mutual fund companies, and insurance companies.
                    </p>

                    <div className="btn-wrapper">
                        <a href="#project" className="btn btn-primary">Learn More</a>
                        <a href="#footer" className="btn btn-outline">Contact Us</a>
                    </div>
                </div>

                <div className="hero-slider" data-slider>
                    <div className="slider-inner">
                        <ul className="slider-container" ref={sliderContainerRef} style={{transform: `translateX(-${sliderContainerRef?.current?.children[currentSlide]?.offsetLeft}px)`}}data-slider-container>
                            <li className="slider-item">
                                <figure className="img-holder" role="none">
                                    <img src={heroSlide2} width="575" height="550" alt="hero1" className="img-cover" />
                                </figure>
                            </li>

                            <li className="slider-item">
                                <figure className="img-holder" role="none">
                                    <img src={heroSlide1} width="575" height="550" alt="hero2" className="img-cover" />
                                </figure>
                            </li>

                            {/* <li className="slider-item">
                                <div className="hero-card">
                                    <figure className="img-holder">
                                        <img src={heroSlide2} width="575" height="550" alt="hero banner" className="img-cover" />
                                    </figure>
                                    <button className="play-btn" aria-label="play adex intro">
                                        <ion-icon name="play" aria-hidden="true"></ion-icon>
                                    </button>
                                </div>
                            </li> */}

                            <li className="slider-item">
                                <figure className="img-holder" role="none">
                                    <img src={heroSlide3} width="575" height="550" alt="hero3" className="img-cover" />
                                </figure>
                            </li>
                        </ul>
                    </div>

                    <button className="slider-btn prev" aria-label="slide to previous" onClick={() => handlePreviousSlide()} data-slider-prev>
                        <ion-icon name="arrow-back"></ion-icon>
                    </button>

                    <button className="slider-btn next" aria-label="slide to next" onClick={() => handleNextSlide()} data-slider-next>
                        <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Hero