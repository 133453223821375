
const Footer = () => {
    return (
        <footer className="footer" id="footer">
            <div className="container grid-list">
                <div className="footer-brand">
                    <a href="#" className="logo">
                        <div className="logo-light">Beyond Advising</div>
                    </a>

                    <p className="footer-text">
                        Beyond Advising &copy; 2024 <br /> All rights reserved.
                    </p>

                    <ul className="social-list">
                        <li>
                            <a href="https://www.linkedin.com/company/beyondadvising" className="social-link">
                                <ion-icon name="logo-linkedin"></ion-icon>
                            </a>
                        </li>
                    </ul>
                </div>

                <ul className="footer-list">
                    <li>
                        <p className="h4 footer-list-title">Get in Touch</p>
                    </li>

                    <li>
                        <address className="footer-text">
                            Lakeland, TN 38002, US
                        </address>
                    </li>

                    <li>
                        <a href="mailto:Info@BeyondAdvising.com" className="footer-link">Info@BeyondAdvising.com</a>
                    </li>
                </ul>

                <ul className="footer-list">
                    <li>
                        <p className="h4 footer-list-title">Explore</p>
                    </li>
                    <li>
                        <a href="#" className="footer-link" role="link">What We Do</a>
                    </li>
                    <li>
                        <a href="#project" className="footer-link" role="link">Services</a>
                    </li>
                    <li>
                        <a href="#advantage" className="footer-link" role="link">Advantages We Offer</a>
                    </li>
                    <li>
                        <a href="#testimonial" className="footer-link" role="link">Testimonials</a>
                    </li>
                    <li>
                        <a href="#about" className="footer-link" role="link">About</a>
                    </li>
                </ul>

                <div className="footer-list">
                    <p className="h4 footer-list-title">Overview</p>
                    <p className="footer-text">
                        Beyond Advising is an agency focused on financial advisors transitioning between broker-dealers. 
                        The services include the preparation and delivery of prepopulated new client account paperwork held 
                        at clearing firms, mutual fund companies, and insurance companies.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer